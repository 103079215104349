import React from "react"
import Layout from "../retech/components/layout/Layout"
import {
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet"
import {
  faSearch,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import Media from "../retech/components/MediaCenter/Index"

import { Link, graphql } from "gatsby"
export default function ProductPage({ data }) {
  const Productfeatured = data.Productfeatured.nodes
  const producttrue = data.producttrue.nodes
  const productwidget = data.productwidget.nodes
  const metadata = data.metadata.nodes
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const [searchTerm, setSearchTerm] = React.useState("")
  const [searchResults, setSearchResults] = React.useState([])
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }
  React.useEffect(() => {
    const results = producttrue.filter(producttrue =>
      producttrue.Title.toLowerCase().includes(searchTerm)
    )
    setSearchResults(results)
  }, [searchTerm])

  return (
    <Layout>
      <Helmet>
        <title>Products</title>

        <meta
          name="description"
          content={data.metadata.nodes[0].ProductMetaDescription}
        />
        <meta
          name="keywords"
          content={data.metadata.nodes[0].Productkeywords}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={data.metadata.nodes[0].ProductMetaTitle}
        />
        <meta
          property="og:description"
          content={data.metadata.nodes[0].ProductMetaDescription}
        />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:title"
          content={data.metadata.nodes[0].ProductMetaTitle}
        />
        <meta
          property="twitter:description"
          content={data.metadata.nodes[0].ProductMetaDescription}
        />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <div className="container faq">
        <div className="col-md-12 my-5 py-5 border-bottom">
          <div className="row">
            <div className="col-md-7 mb-5">
              <h1 className="font-weight-bolder">
                Search Our Metallurgical Processing Products
              </h1>
              <p className="text-muted">
                Identifying customer needs, as well as understanding the importance of producing relevant, viable, and cost-effective equipment, is the foundation upon which Retech is built.              </p>
            </div>
            <div className="col-md-5 align-self-center search-loup mb-5">
              <InputGroup.Append className="shadow-none has-search">
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="Search"
                  className="shadow-none bg-light border-0"
                  value={searchTerm}
                  onChange={handleChange}
                />
                <span className=" form-control-feedback">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </InputGroup.Append>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 d-block d-sm-none mb-5 dropdown-filter">
              <DropdownButton
                id="dropdown-filter"
                title="Product Category"
                variant="secondary"
              >
                {Productfeatured?.map((node, index) => {
                  return (
                    <>
                      <Dropdown.Item href={node.CTALink}>
                        {node.Title}
                      </Dropdown.Item>
                    </>
                  )
                })}
              </DropdownButton>
            </div>
            <div className="col-md-4 d-none d-sm-block">
              <h6 className="font-weight-bold">Product Category</h6>

              {Productfeatured?.map((node, index) => {
                return (
                  <>
                    <small>
                      <Link
                        className="text-dark"
                        to={"/products/" + node.CTALink}
                      >
                        {node.Title}
                      </Link>
                    </small>
                    <br></br>
                  </>
                )
              })}
            </div>
            <div className="col-md-8 pb-5 ">
              <h5 className="font-weight-bolder mb-3">Featured</h5>
              <div className="row">
                {searchResults?.map((node, index) => {
                  return (
                    <div
                      className="col-md-4 section-link"
                      style={{ marginTop: "1rem" }}
                      key={index}
                    >
                      <div className="pickgradient">
                        <Link to={"/products/" + node.CTALink}>
                          <img alt={node.Title} width="100%" src={node.Image} />
                          <p className="location">
                            {node.Title}
                            <br />
                          </p>
                          <p className="parallelogram mb-4 mr-3 active-filter">
                            <Link
                              className="text-dark "
                              to={"/products/" + node.CTALink}
                            >
                              MORE
                            </Link>
                          </p>
                        </Link>
                      </div>
                    </div>
                  )
                })}
                {producttrue.filter(producttrue =>
                  producttrue.Title.toLowerCase().includes(searchTerm)
                ).length === 0 && <p>No results found, please try a different search query or return <a href='/'>Home</a></p>}
              </div>
            </div>
          </div>
        </div>
        <div className="Container">
          <div className="col-md-12 border-bottom border-top  pt-3">
            <div className="row py-5">
              {productwidget.map((node, index) => {
                return (
                  <>
                    <div className="col-md-7 mx-auto text-center">
                      <h3 className="mb-4 font-weight-bolder">
                        {node.productWidgetTitle}
                      </h3>
                      <p className="product-par">
                        {" "}
                        {node.productWidgetIntro}
                      </p>
                      <Link
                        to={node.productWidgetCTALink}
                        className="font-weight-bolder see-all justify-content-center"
                      >
                        <p>{node.productWidgetCTAText}</p>
                        <FontAwesomeIcon
                          className="ml-3"
                          icon={faChevronCircleRight}
                        />{" "}
                      </Link>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="col-md-12 py-5 Media ">
            <Media />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    Productfeatured: allProductsJson(sort: {fields: OrderId, order: ASC}, filter: {Title: {ne: null}}) {
      nodes {
        CTALink
        Image
        Title
      }
    }
    producttrue: allProductsJson(sort: {fields: OrderId, order: ASC}, filter: { Featured: { eq: true } }) {
      nodes {
        CTALink
        Image
        Featured
        Title
      }
    }
    productwidget: allProductJson(
      filter: { productWidgetTitle: { ne: null } }
    ) {
      nodes {
        productWidgetCTALink
        productWidgetCTAText
        productWidgetIntro
        productWidgetTitle
      }
    }
    metadata: allMetadataJson(filter: { ProductMetaTitle: { ne: null } }) {
      nodes {
        ProductMetaDescription
        ProductMetaTitle
        Productkeywords
      }
    }
  }
`
